import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../redux/actions/webSocketActions';

import SEO from '../components/SEO';
import TOC from '../components/TOC';

function PrivacyPolicy({ siteConfig }) {
  return (
    <>
      <SEO title={'Privacy Policy'} />
      <div className="page-container text-container">
        <h1>{' ' + siteConfig.brand + ' '} Privacy Policy</h1>

        <div className="page">
          <div className="inner">
            <TOC />
            <div className="article-text">
              <p>Last Updated: January 03, 2022</p>
              <h2>Introduction</h2>
              <p>
                This Privacy Policy (the &ldquo;Policy&rdquo;) describes how
                {' ' + siteConfig.brand + ' '} LLC, an Arizona limited liability
                company (&ldquo;{siteConfig.brand}&rdquo;, which may hereinafter
                be referred to as &ldquo;us,&rdquo; &ldquo;we,&rdquo; and
                &ldquo;our&rdquo;), collects, uses, and shares your personal
                data. This Policy applies to {' ' + siteConfig.brand + ' '} in
                general, the {' ' + siteConfig.brand + ' '} website located at{' '}
                <a href={'http://www.' + siteConfig.domain} className="link">
                  www.{siteConfig.domain}
                </a>{' '}
                and any products and services offered, displayed or sold by{' '}
                {' ' + siteConfig.brand + ' '} (collectively the
                &ldquo;Site&rdquo;).
              </p>
              <p>
                We have attempted to provide clear explanations of this Policy
                so that you can decide for yourself whether to use our Site, or
                not. By using our Site, you consent to the collection and use of
                this information as outlined in this Policy. If you do not agree
                to this Policy, and our{' '}
                <em>
                  <a href="/terms" className="link">
                    Terms of Service
                  </a>
                </em>
                , you cannot use our Site. If you have questions or concerns
                about this Policy, please contact us by email at:{' '}
                <a
                  href={'mailto:support@' + siteConfig.domain}
                  className="link">
                  support@{siteConfig.domain}
                </a>{' '}
                or by postal mail at: {' ' + siteConfig.brand + ' '} LLC - 2525
                E Camelback Rod STE 810 - Phoenix, AZ
              </p>
              <p>
                PLEASE REVIEW THIS POLICY CAREFULLY. BY USING THIS SITE, YOU ARE
                AGREEING TO BE BOUND BY THIS POLICY, AND ANY OTHER TERMS,
                POLICIES AND DISCLOSURES INCORPORATED HEREIN BY REFFERENCE.
              </p>
              <h2>Overview of Policy</h2>
              <p>
                {' ' + siteConfig.brand + ' '} has created this Policy to
                demonstrate our firm commitment to the privacy of our users. We
                are committed to respecting your privacy and recognizing your
                need for appropriate protection and management of your personal
                data.
              </p>
              <p>
                {' ' + siteConfig.brand + ' '} considers any data that
                identifies, relates to, describes, is reasonably capable of
                being associated with, or could reasonably be linked, directly
                or indirectly, with you, as &ldquo;Personal Data&rdquo;.&nbsp;
                This means that data that can directly identify you, such as
                your name, is Personal Data, and also data that does not
                directly identify you, but that can reasonably be linked to you,
                such as service-related information, is Personal Data.
                Aggregated data is considered non-personal data for the purposes
                of this Policy.
              </p>
              <p>
                The purpose of this Policy is to disclose the type of Personal
                Data that we collect, how we use such information, who we may
                share it with, and the choices you have regarding your ability
                to review and correct it. By visiting our Site, you consent to
                our collection and use of your Personal Data as described in
                this Policy.
              </p>
              <p>
                <strong>
                  It is important that you understand what{' '}
                  {' ' + siteConfig.brand + ' '} is, and does.
                  {' ' + siteConfig.brand + ' '} provides a platform through
                  which users can purchase goods or services from third parties.{' '}
                  {' ' + siteConfig.brand + ' '} acts as a marketplace platform
                  for these third party service providers and their products.
                  You understand and agree that by using our Site, you are
                  directing {' ' + siteConfig.brand + ' '} to intentionally
                  disclose Personal Data about you to these third parties or are
                  using {' ' + siteConfig.brand + ' '} to intentionally interact
                  with these third parties.{' '}
                </strong>
              </p>
              <p>
                For your benefit, the most recent version of this Policy is
                posted in the footer of our home page.
              </p>
              <h2>Personal Data We Collect</h2>
              <p>
                The type and scope of Personal Data that we collect depends on,
                among other things, the services we want to offer you and how
                you choose to interact with our Site. In general, we may collect
                the following kinds of Personal Data, either from you directly,
                through your use of our Site, or from third party service
                providers, business partners, affiliates, or contractors:
              </p>
              <ul>
                <li>
                  <strong>Contact Information.</strong> Data such as your name,
                  email address, physical address, phone number, or other
                  contact information.
                </li>
                <li>
                  <strong>Third Party Information.</strong> Data that you have
                  shared with, or was generated by, other third parties, along
                  with ID codes, t-codes, account numbers, or other means of
                  identifying you within those third party platforms.
                </li>
                <li>
                  <strong>Service-related Information.</strong> Data such as
                  your start service or end service date, previous, current or
                  future address, credit history and score, current or past
                  service providers, account and billing information, plans
                  selections, order status, historical usage data, or other
                  service-related information.
                </li>
                <li>
                  <strong>Proof of Identity.</strong> Data such as your
                  Driver&rsquo;s License and number, or other acceptable photo
                  ID, and State of issuance, Social Security number, or other
                  evidence that may be needed, or can be used, to confirm your
                  identity.
                </li>
                <li>
                  <strong>Electronic Interaction. </strong>Data on when and how
                  you interact with our Site, including a digital recording of
                  your sessions, if and when you open our email communications,
                  and which internal links you click.
                </li>
                <li>
                  <strong>Device Information.</strong> Data from which your
                  device could be identified, such as an IP address, device
                  serial number, or about your device, such as screen
                  resolutions and browser type.
                </li>
                <li>
                  <strong>Payment Information.</strong> Data about your billing
                  address and method of payment, such as bank details, credit,
                  debit, or other payment card information.
                </li>
                <li>
                  <strong>Location Attributes.</strong> Data such as square
                  footage, year built, number of occupants, ESIID, meter number,
                  and other location specific information.
                </li>
                <li>
                  <strong>Other Information You Provide.</strong> Other details
                  such as the content of your communications with us, including
                  interactions with customer support and contacts through social
                  media channels, or other related interactions.
                </li>
                <li>
                  <strong>Other Information from Third Party Providers</strong>.
                  We may receive other information or data from third parties
                  about you that we don&rsquo;t use or retain.
                </li>
              </ul>
              <p>
                Just to be clear, you are not obligated to provide any of the
                Personal Data that we may request. However, if you choose not to
                you may be unable to fully utilize our Site. Certain activities,
                such as ordering certain services, cannot be completed without
                additional Personal Data from you.
              </p>
              <h2>Sources of Personal Data Collection</h2>
              <p>
                {' ' + siteConfig.brand + ' '} collects Personal Data from the
                following sources:
              </p>
              <ul>
                <li>
                  Directly from you through your use of the Site or through
                  communications with us,
                </li>
                <li>Service providers,</li>
                <li>Business partners,</li>
                <li>Affiliates,</li>
                <li>Third parties authorized to share information with us,</li>
                <li>Publicly available sources and registries,</li>
                <li>Government entities.</li>
              </ul>
              <h2>Use of Personal Data</h2>
              <p>
                {' ' + siteConfig.brand + ' '} uses Personal Data in a variety
                of ways, the most common of which are outlined below.
              </p>
              <ul>
                <li>
                  <strong>Customizing Our Site. </strong>Personal Data is used
                  by our Site to personalize what services you are shown so that
                  only relevant data, products and providers are shown to you.
                </li>
                <li>
                  <strong>Process Your Transactions. </strong>Personal Data is
                  used to verify your identity, obtain credit references and
                  process transactions initiated by you though our Site.
                </li>
                <li>
                  <strong>Communicate with You.</strong> Personal Data may be
                  used to make initial contact with you, to respond to specific
                  request from you, to reach out to you about your transactions
                  or account, to market our products and services, to request
                  information or feedback, or for any other reasonable purpose.
                </li>
                <li>
                  <strong>Service and Product Development. </strong>Personal
                  Data may be used to identify popular services, enhance Site
                  usefulness, and spot locations in our Site that experience
                  high bounce, or end session, rates.
                </li>
                <li>
                  <strong>Fraud Prevention. </strong>Personal Data may be used
                  to identify and help prevent fraud.
                </li>
                <li>
                  <strong>For Regulatory and Legal Compliance.</strong> Personal
                  Data may be used or shared as necessary, to comply with any
                  lawful government request or court order, to protect or defend
                  us or our affiliates in a regulatory or legal proceeding, to
                  enforce our Terms of Service, or to otherwise protect our
                  business interests.
                </li>
                <li>
                  <strong>Other Business Purposes.</strong> Personal Data may
                  also be used for any one of the specific &ldquo;business
                  purposes&rdquo; described in the California Consumer Privacy
                  Act. Namely, auditing related to a current interaction with
                  the consumer; detecting security incidents, protecting against
                  malicious, deceptive, fraudulent or illegal activity, and
                  prosecuting those responsible for that activity; identifying
                  and debugging errors that impair functionality or other
                  permissible short-term transient use.
                </li>
              </ul>
              <h2>Sharing of Personal Data</h2>
              <p>
                {' ' + siteConfig.brand + ' '} may share your Personal Data with{' '}
                {' ' + siteConfig.brand}-affiliated companies, service
                providers, or third party business partners, affiliates,
                contractors, or other entities.
              </p>
              <ul>
                <li>
                  <strong>Service Providers. </strong>If you purchase a product
                  or service from our Site we will likely need to share certain
                  Personal Data about you with that service provider to complete
                  the transaction.
                </li>
                <li>
                  <strong>Third Party Business Partners.</strong>{' '}
                  {' ' + siteConfig.brand + ' '} partners with a wide range of
                  third party partners, such as real estate and property
                  management companies, to generate leads and to introduce our
                  Site to you. In return we may share certain Personal Data,
                  such as if, when and how you utilized our Site, or by
                  providing requested documentation, such as &lsquo;proof of
                  service&rsquo;, if you were to purchase certain products
                  through us, such as an insurance policy or electricity plan.
                </li>
                <li>
                  <strong>Third Party Affiliates.</strong>{' '}
                  {' ' + siteConfig.brand}, through our affiliate marketing
                  program, works with third party affiliates to push traffic
                  (users) to our Site. In return we may share certain Personal
                  Data with them, such as who they sent to us and whether or not
                  those users used any of our services.
                </li>
                <li>
                  <strong>Third Party Contractors.</strong> If, as part of our
                  services to you, we need to perform certain tasks, such as
                  obtaining credit scores or processing a payment, we may share
                  your Personal Data with third party contractors to perform
                  those tasks.
                </li>
                <li>
                  <strong>Business Service Providers.</strong>{' '}
                  {' ' + siteConfig.brand + ' '} may engage third parties to act
                  as service providers and perform certain tasks on our behalf,
                  such as processing or storing data, including personal data,
                  in support of our Site.
                </li>
                <li>
                  <strong>Other Entities.</strong> There may be situations where
                  other entities, not covered above, may have a legitimate need
                  for certain Personal Data to perform certain tasks, such as
                  responding to a request from you. Depending on the task, we
                  may share certain Personal Data with them to complete the
                  task.
                </li>
              </ul>
              <h2>Cookies and Other Data Technologies</h2>
              <p>
                We use cookies and other Data Technologies to create a more
                personalized and optimized Site experience for our users. With
                the use of cookies and other Data Technologies, we can tailor
                your Site experience to better reflect your specific situation
                and provide you with more relevant information, including
                information about products and services offered by this Site and
                others. We will use the cookies and other Data Technologies to
                analyze your online behavior and may combine this information
                with other Personal Data we have on our users.
              </p>
              <p>
                Information gathered may include the date and time of visits,
                pages viewed and in what order, overall time spent at our Site
                or on individual pages, the sites visited just before and just
                after visiting our site, the name of the domain and host from
                which you access the Internet, the IP address of the computer
                you are using, and the type of browser, device or operating
                system used. This information is logged for internal use by{' '}
                {' ' + siteConfig.brand}, including helping diagnose technical
                problems and to administer the Site in a manner that allows us
                to constantly improve the quality of the services provided to
                you.
              </p>
              <p>We use the following types of cookies:</p>
              <ul>
                <li>
                  <strong>Strictly Necessary Cookies</strong>. These cookies are
                  required in order for the Site to operate properly and to
                  allow you to browse our Site and use our services as intended.
                  Without these cookies, services such as shopping carts and
                  e-billing cannot be provided.
                </li>
                <li>
                  <strong>Functional Cookies</strong>. These cookies allow our
                  Site to recognize and remember you and the choices you make
                  while using our Site.
                </li>
                <li>
                  <strong>Analytical/Performance Cookies</strong>. These cookies
                  allow us to recognize and count the number of visitors and to
                  see how visitors move around our website when they are using
                  it. This helps us to improving the way our website works, for
                  example, by ensuring that users are finding what they are
                  looking for easily. These cookies are also used to let
                  affiliates know if you came to our Site from one of their
                  websites and if your visit resulted in the purchase of a
                  product or service from us, including details of the product
                  or service purchased.
                </li>
                <li>
                  <strong>Targeted/Advertising Cookies</strong>. These cookies
                  record your visit to our website, the pages you have visited
                  and the links you have followed. We may use this information
                  to make our website and the advertising displayed on it more
                  relevant to your interests. We may also share this information
                  with third parties for this purpose.
                </li>
              </ul>
              <p>
                If you want to know when cookies are being placed on your
                computer you can set your browser to alert you when a website
                attempts to place one.&nbsp; If you do not wish to transmit any
                cookie information, you can disable the cookie function in your
                browser.
              </p>
              <p>
                <strong>Pixel Tags</strong>. We may use &ldquo;pixel tags&rdquo;
                (aka &ldquo;web beacons&rdquo; or &ldquo;clear
                GFI&rsquo;s&rdquo;) or similar technologies, in the Site and/or
                in our communications with you, for example, to enable us to
                know whether you have visited a web page or received a message.
                A pixel tag is typically a one-pixel, transparent image
                (although it can be a visible image as well), located on a web
                page or in an email or other type of message, which is retrieved
                from a remote site on the Internet enabling the verification of
                an individual&rsquo;s viewing or receipt of a web page or
                message.
              </p>
              <p>
                <strong>FullStory.</strong> We may use FullStory to monitor and
                record your interaction with our Site. The FullStory analytics
                service uses heat mapping and records mouse movements, clicks,
                scrolls, and keystrokes during your use of our Site. This
                information helps us identify and address technical issues and
                understand usage of our Site, which we use to improve the Site
                for you. You can opt-out of our use of FullStory here:{' '}
                <a href="https://www.fullstory.com/optout/" className="link">
                  https://www.fullstory.com/optout/
                </a>
              </p>
              <p>
                <strong>Google Analytics</strong>. we use Google Analytics to
                help us understand how our users use our products and services
                &ndash; you can read more about how Google uses our information
                here:{' '}
                <a
                  href="https://www.google.com/intl/en/policies/privacy"
                  className="link">
                  https://www.google.com/intl/en/policies/privacy
                </a>{' '}
                and you can also opt-out of Google Analytics here:{' '}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  className="link">
                  https://tools.google.com/dlpage/gaoptout
                </a>
              </p>
              <h2>Additional Disclosures</h2>
              <p>
                <strong>Protection of Personal Data</strong>. The security of
                your Personal Data is very important to us. When we collect your
                Personal Data online, we use reasonable physical, technical, and
                administrative procedures to safeguard and protect it from
                unauthorized access, use, alteration and disclosure. However,
                due to the inherent open nature of the Internet, we cannot
                guarantee that your Personal Data will be completely free from
                unauthorized access by third parties such as hackers and your
                use of our Site demonstrates your assumption of this risk.
              </p>
              <p>
                <strong>How Long We Keep Personal Data</strong>. We retain
                information that we collect about you or that you provide to us,
                including any Personal Data, for business purposes and as long
                as is reasonably necessary to provide you with our products and
                services. We will also retain the information as reasonably
                necessary to comply with our legal obligations, resolve disputes
                and enforce our agreements. We may also retain cached or
                archived copies of your information for a reasonable period of
                time.
              </p>
              <p>
                <strong>Children and Personal Data.</strong> Minors (children
                under the age of 18) are not eligible to use the Site
                unsupervised, and we ask that children do not submit any
                personal information to us.&nbsp; If you are under the age of
                18, you may only use the Site in conjunction with, and under the
                constant supervision and consent of, your parents or
                guardians.&nbsp; But in NO case do we want nor solicit any
                personal information from minor children, i.e. those under the
                age of eighteen (18) years old.&nbsp; In certain other countries
                there are higher legal minimums for using a website unsupervised
                &ndash; in those countries the higher legal limits apply.&nbsp;
              </p>
              <p>
                We take special care to protect the privacy needs of children
                and encourage parents to be an active participant in their
                child&rsquo;s online activities. Our Site does not target and is
                not intended for children under the age of 18, and we will not
                knowingly collect Personally Identifiable Information from them.
                If we discover personal data from a child through our Site, we
                will eliminate that data.
              </p>
              <p>
                <strong>Do Not Track Request.</strong>{' '}
                {' ' + siteConfig.brand + ' '} does not track its customers over
                time and across third party websites to provide targeted
                advertising and therefore does not respond to Do Not Track (DNT)
                signals.
              </p>
              <p>
                <strong>Links to Other Websites.</strong> Our Site contains
                links to third party websites. If you click on a third party
                link, you will be taken to a platform we do not control. This
                Policy does not apply to the information practices of that
                website or platform. We encourage you to read the privacy
                policies of those companies carefully before sharing additional
                Personal Data with them. We are not responsible for these third
                parties.
              </p>
              <p>
                <strong>Third Party Hosting</strong>. We may contract with one
                or more third parties to maintain and host the Site.&nbsp;
                Therefore, any information you submit, including personal
                information, will be placed and stored on a computer server
                maintained by this third party host.&nbsp; Any such third party
                has agreed to implement technology and security features and
                strict policy guidelines to safeguard the privacy of your
                personal information from unauthorized access or improper use.
              </p>
              <p>
                <strong>California Privacy Rights.</strong> California consumers
                have a right to knowledge, access, and deletion of their
                personal information under the California Consumer Privacy Act.
                Click here to go to our{' '}
                <em>
                  <a href="/ccpa/" className="link">
                    California Consumer Privacy Act Disclosure
                  </a>
                </em>
                .
              </p>
              <p>
                <strong>Safe Harbor.</strong> {' ' + siteConfig.brand + ' '}{' '}
                adheres to the U.S. safe harbor privacy principles of notice,
                choice, onward transfer, security, data integrity, access, and
                enforcement.&nbsp; Accordingly, {' ' + siteConfig.brand}
                &rsquo;s transfer of your personal data to non-European Union
                nations that do not meet the stringent European Union
                &ldquo;adequacy&rdquo; standard for privacy protection is
                prohibited, and {' ' + siteConfig.brand + ' '} will not transfer
                your personal data to any such nation.&nbsp; If you have any
                questions regarding this policy, please contact us through the
                Site.&nbsp; &nbsp;
              </p>
              <p>
                <strong>Users Outside of the United States.</strong> Our Site is
                hosted in the United States, our products and services are
                provided from the United States, and our Site is targeted to our
                users who are located in the United States. Certain information
                may be stored on servers in other countries on the
                &ldquo;cloud&rdquo; or other similar distributed hosting
                platforms. If you are a user accessing our Site from the
                European Union, Asia or any other region with laws governing
                personal data collection, use and disclosure that differ from
                United States laws, please note that you are transferring your
                Personal Data outside your home jurisdiction, and that by
                providing your Personal Data to us, you are consenting to the
                transfer of your Personal Data to the United States and other
                jurisdictions as indicated above, and to our use and disclosure
                of your Personal Data in accordance with this Policy.
              </p>
              <p>
                <strong>Changes to This Policy</strong>. We may modify this
                Policy from time to time. When we make material changes to this
                Policy, we will post a link to the revised Policy on the
                homepage of our site. You can tell when this Policy was last
                updated by looking at the date at the top of the Policy. Any
                changes to this Policy will become effective upon posting of the
                revised Policy on the site. Use of the Site, any of the
                services, and/or providing consent to the updated Policy
                following such changes constitutes your acceptance of the
                revised Policy then in effect.
              </p>
              <p>
                Click here to{' '}
                <a href="/contact" className="link">
                  Contact Us
                </a>{' '}
                if you have any questions or concerns regarding this Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
